<template>
	<div class="wrap">
		<div class="user-info flex b-w f-ai-c" @click="editUserInfo">
			<img :src="avatar" />
			<span class="user-name">{{name}}</span>
			<i class="r-icon"></i>
		</div>
		<ul class="b-w flex ">
			<li @click="toPatients">
				<i class="l-icon l-icon-1"></i>
				<span>就诊人管理</span>
				<i class="r-icon"></i>
			</li>
			<li @click="toOrders">
				<i class="l-icon l-icon-2"></i>
				<span>药品订单</span>
				<i class="r-icon"></i>
			</li>
			<li @click="toConsultations">
				<i class="l-icon l-icon-4"></i>
				<span>问诊单</span>
				<i class="r-icon"></i>
			</li>
			<li @click="toOn">
				<i class="l-icon l-icon-3"></i>
				<span>关于</span>
				<i class="r-icon"></i>
			</li>
		</ul>
	</div>
</template>
<script>
import { getUserInfo } from '@/request/api/user';
export default {
	data () {
		return {
			name: '设置昵称',
			avatar: require('../../assets/images/head-img.png')
		};
	},
	created () {
		this.getUser();
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.showGoBack = true;
			// console.log('this.$root====', this.$root);
			this.$root.go('/user-center');
		},
		getUser () {
			getUserInfo().then(data => {
				if (data && data === 'retry') {
					this.getUser();
				} else if (data) {
					if (data.avatar) this.avatar = data.avatar;
					if (data.name) this.name = data.name;
				}
			});
		},
		editUserInfo () {
			this.$root.go('/modify-name', { name: this.name });
		},
		toPatients () {
			this.$root.go('/patient-list');
		},
		toOrders () {
			this.$root.go('/order-list');
		},
		toConsultations () {
			this.$root.go('/consultation-list');
		},
		toOn () {
			// this.$root.go('/consultation-list');
			// this.$root.elMsg('功能正在紧急开发中！', 'fail');
			this.$root.go('/about-us');
		}
	}
};
</script>
<style lang="less" scoped>
	.user-info {
		padding: 20px 15px;
		display: flex;
		align-items: center;
		img {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			margin-right: 15px;
		}
		.user-name {
			height: 23px;
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #3B3D40;
			line-height: 23px;
			margin-right: 5px;
		}
	}
	ul {
		margin-top: 10px;
		padding: 0 15px;
		flex-direction: column;
		li {
			position: relative;
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #262626;
			height: 50px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #F0F0F0;
			&:nth-child(4) {
				border: none;
			}
			.l-icon {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				&-1 {
					background: url('../../assets/images/user-1.png') no-repeat;
					background-size: contain;
				}
				&-2 {
					background: url('../../assets/images/user-2.png') no-repeat;
					background-size: contain;
				}
				&-3 {
					background: url('../../assets/images/user-3.png') no-repeat;
					background-size: contain;
				}
				&-4 {
					background: url('../../assets/images/user-4.png') no-repeat;
					background-size: contain;
				}
			}
			.r-icon {
				position: absolute;
				right: 0;
			}
		}
	}
	.r-icon {
		width: 8px;
		height: 14px;
		background: url('../../assets/images/right-arrow.png') no-repeat;
		background-size: contain;
	}
</style>