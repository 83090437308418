// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/user-1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/user-2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/user-3.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/user-4.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/right-arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".user-info[data-v-3d021db0] {\n  padding: 1.25rem 0.9375rem;\n  display: flex;\n  align-items: center;\n}\n.user-info img[data-v-3d021db0] {\n  width: 4.375rem;\n  height: 4.375rem;\n  border-radius: 50%;\n  margin-right: 0.9375rem;\n}\n.user-info .user-name[data-v-3d021db0] {\n  height: 1.4375rem;\n  font-size: 1.25rem;\n  font-family: PingFangSC-Medium, PingFang SC;\n  font-weight: 500;\n  color: #3B3D40;\n  line-height: 1.4375rem;\n  margin-right: 0.3125rem;\n}\nul[data-v-3d021db0] {\n  margin-top: 0.625rem;\n  padding: 0 0.9375rem;\n  flex-direction: column;\n}\nul li[data-v-3d021db0] {\n  position: relative;\n  font-size: 0.9375rem;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #262626;\n  height: 3.125rem;\n  display: flex;\n  align-items: center;\n  border-bottom: 0.0625rem solid #F0F0F0;\n}\nul li[data-v-3d021db0]:nth-child(4) {\n  border: none;\n}\nul li .l-icon[data-v-3d021db0] {\n  width: 1.25rem;\n  height: 1.25rem;\n  margin-right: 0.625rem;\n}\nul li .l-icon-1[data-v-3d021db0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain;\n}\nul li .l-icon-2[data-v-3d021db0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: contain;\n}\nul li .l-icon-3[data-v-3d021db0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  background-size: contain;\n}\nul li .l-icon-4[data-v-3d021db0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;\n  background-size: contain;\n}\nul li .r-icon[data-v-3d021db0] {\n  position: absolute;\n  right: 0;\n}\n.r-icon[data-v-3d021db0] {\n  width: 0.5rem;\n  height: 0.875rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat;\n  background-size: contain;\n}\n", ""]);
// Exports
module.exports = exports;
